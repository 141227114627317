<template>
    <div class="container ">
        <br />
        <div class="box"> 
             <div @click="$router.push('/dashboard')" style='text-align:left; cursor:pointer; '> <i  style=" " class="fas fa-chevron-circle-left"></i> Back</div>
            <h5>Connect to SeasonCast Premium</h5>
            <p>GameTaper Elite is currently included in the SeasonCast Premium Membership! Login or enter access token of a SeasonCast account:</p><br />
            <div v-if="page == 0">
                <button @click="page = 1" class="btn btn-success">Login into SeasonCast</button><br /> <br />Or  <br /><br />     
                <button @click="page = 2" class="btn btn-dark">Enter Access Token</button>
            </div>
            <div style="text-align:left" v-if="page == 1">
                <p>SeasonCast Broadcaster Login</p>
        <label >Email: </label>
    <input type="text" class="form-control" v-model="email"  placeholder="Email"><br />
    <label >Password: </label>
    <input type="password" class="form-control" v-model="password"  placeholder="Password">
     <br />
        <button @click="login" class="btn btn-primary">Login to SeasonCast</button>

            </div>
            <div v-if="page == 2">
            <label >Access Token: </label>
    <input type="text" class="form-control" v-model="access_code"  placeholder="Copy Token Code from SeasonCast.com">
    <br />
    <button @click="getCode" class="btn btn-success">Complete</button>
            </div>
          
        <br />        <br />
        <b>{{ reason }}</b>
        </div>
      
           
    </div>
</template>
<script>
import firebase from "firebase";
import axios from "axios";
//var db = firebase.firestore();
require("firebase/functions");
 var functions = firebase.functions()
export default {
    methods:{
        login: async function(){
              this.reason = undefined
             var response = await axios.post('https://app.seasoncast.com/v2/broadcaster/login', {
    email: this.email,
    password: this.password,
  })

  if(!response.data.success){
    this.reason = response.data.reason
    return
  }

this.access_code = response.data.token
this.getCode()
        },
        getCode: async function(){
  this.reason = undefined
        //   await db.collection("SeasonCastToken").doc(this.user.uid).set({access_token: this.access_code}, { merge: true })

                 var loader = this.$loading.show({
                  // Optional parameters
          
                });
        
               var  addMessage = functions.httpsCallable('connectSeasonCast')
                
              var result = await  addMessage({ team_id: this.$route.params.team_id, token: this.access_code  })

    console.log(result)
     loader.hide()
            if (result.data.success){
              this.$router.push('/dashboard')
                }else{
                    this.reason = result.data.reason
                }
                        },
    },
    mounted: async function(){
this.user = await  new Promise((resolve, reject) => {
     const unsubscribe = firebase.auth().onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
     }, reject);
  });


    },
    components:{
        
},
    data() {
    return {
        user: undefined,
        access_code: "",
         email: "",
         password: "",
        reason: undefined,
        page: 0,
    }
    }
}
</script>

<style scoped>
.box{
    border-radius: 1em;
    background-color: white;
    padding: 1em;
   
}
</style>